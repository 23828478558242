import React from "react"

import Layout from "../components/layout"

import {
  DefaultWrapper,
  MainH3,
  MainH2,
  MainParagraph,
  MainTextLink,
  PrimaryBtn,
  SupTitle,
  SecondaryBtn,
} from "../utils/GlobalComponents"

const ViewComponents = () => {
  return (
    <Layout>
      <DefaultWrapper>
        <h1>Components</h1>
        <br />
        <MainH2>LymeMIND Conference 2020</MainH2>
        <br />
        <MainH3>
          Advancing <strong>Lyme disease research</strong> through an integrated
          network of researchers, patients, physicians, and sponsors
        </MainH3>
        <br />
        <MainParagraph>
          LymeMIND aims to develop an integrated knowledge base to help Lyme
          disease researchers identify novel biomarkers and therapeutic target
          candidates. We bring together leaders in Lyme disease research through
          collaborations, an integrative web portal, and an annual symposium.
        </MainParagraph>
        <br />
        <MainTextLink gradient={"orange"} to="/">
          View Text
        </MainTextLink>
        <br /> <br />
        <MainTextLink gradient={"green"} to="/">
          View Text
        </MainTextLink>
        <br /> <br />
        <PrimaryBtn primary={"A"}>Primary button A</PrimaryBtn>
        <br /> <br />
        <PrimaryBtn primary={"B"}>Primary button B</PrimaryBtn>
        <br /> <br />
        <SecondaryBtn secondary={"A"}>
          <span>Secondary Button A</span>
        </SecondaryBtn>
        <br /> <br />
        <SecondaryBtn secondary={"B"}>
          <span>Secondary button B</span>
        </SecondaryBtn>
        <br /> <br />
        <SupTitle>The research</SupTitle>
      </DefaultWrapper>
    </Layout>
  )
}

export default ViewComponents
